const getBrowserType = () => {
    const ua = navigator.userAgent.toLowerCase();
    const testUa = (regexp) => regexp.test(ua);
    let system = "unknown";
    if (testUa(/windows|win32|win64|wow32|wow64/ig)) {
        system = "windows";
    }
    else if (testUa(/macintosh|macintel/ig)) {
        system = "macos";
    }
    else if (testUa(/x11/ig)) {
        system = "linux";
    }
    else if (testUa(/android|adr/ig)) {
        system = "android";
    }
    else if (testUa(/ios|iphone|ipad|ipod|iwatch/ig)) {
        system = "ios";
    }
    let shell = "none";
    if (testUa(/micromessenger/ig)) {
        shell = "wechat";
    }
    else if (testUa(/qqbrowser/ig)) {
        shell = "qq";
    }
    else if (testUa(/ucbrowser/ig)) {
        shell = "uc";
    }
    else if (testUa(/quark/ig)) {
        shell = "quark";
    }
    return {
        isAndroid: system === "android",
        isIOS: system === "ios",
        isWeChat: shell === "wechat"
    };
};
function AutoResponse(size = 750, dir = "x") {
    const target = document.documentElement;
    if (dir === "y") {
        if (target.clientHeight >= 600) {
            target.style.fontSize = "80px";
        }
        else {
            target.style.fontSize = `${target.clientHeight / size * 100}px`;
        }
    }
    else {
        if (target.clientWidth >= 600) {
            target.style.fontSize = "80px";
        }
        else {
            target.style.fontSize = `${target.clientWidth / size * 100}px`;
        }
    }
}
function ParseUrlSearch() {
    const { search } = location;
    return search ? search.replace(/(^\?)|(&$)/g, "").split("&")
        .reduce((t, v) => {
        const [key, val] = v.split("=");
        t[key] = decodeURIComponent(val);
        return t;
    }, {}) : {};
}
export { AutoResponse, getBrowserType, ParseUrlSearch };
