import React, { useState, useEffect } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, Mousewheel } from "swiper";
import { AutoResponse, getBrowserType, ParseUrlSearch } from "../../utils/browser";
import "./index.scss";
import "swiper/css";
import "swiper/css/mousewheel";
import Classnames from "classnames";
export default function Home() {
    const [mobileType, setMobileType] = useState("");
    const [mySwiper, setMySwiper] = useState(null);
    const [index, setIndex] = useState(0);
    const [tipsStatus, setTipsStatus] = useState(false);
    const [version, setVersion] = useState();
    useEffect(() => {
        var _a;
        (_a = window === null || window === void 0 ? void 0 : window.ns) === null || _a === void 0 ? void 0 : _a.call(window, "send", "event", "wtcg_box_client", "exposure_new_2_391_12", "炉石传说盒子-官网曝光");
        AutoResponse();
        const { isAndroid, isIOS } = getBrowserType();
        setMobileType(isAndroid ? "android" : isIOS ? "ios" : "");
        const { pkg } = ParseUrlSearch();
        if (pkg) {
            const ver = pkg.replace(new RegExp("pchz", "g"), "");
            if (!isNaN(+ver)) {
                setVersion(+ver);
            }
        }
    }, []);
    return (React.createElement("div", { className: "mobile-view" },
        React.createElement("div", { className: "mobile-swiper-wrapper" },
            React.createElement(Swiper, { className: "swiper-wrapper pr", controller: { control: mySwiper }, direction: "vertical", modules: [Controller, Mousewheel], mousewheel: true, autoplay: true, onSlideChangeTransitionEnd: e => setIndex(e.realIndex), onSwiper: setMySwiper },
                React.createElement(SwiperSlide, { key: "0" },
                    React.createElement("div", { className: "mobile-swiper-item first" },
                        React.createElement("div", null))),
                React.createElement(SwiperSlide, { key: "1" },
                    React.createElement("div", { className: "mobile-swiper-item second" },
                        React.createElement("div", null))),
                React.createElement(SwiperSlide, { key: "2" },
                    React.createElement("div", { className: "mobile-swiper-item third" },
                        React.createElement("div", null))))),
        React.createElement("div", { className: `mobile-topbar ${mobileType}` },
            React.createElement("a", { className: "download", href: `https://xm.gameyw.netease.com/wyds_dl_lushibox${version !== null && version !== void 0 ? version : ""}` })),
        React.createElement("div", { className: "mobile-dowmload-btn", onClick: () => setTipsStatus(true) }),
        React.createElement("a", { className: "mobile-dowmload-mobile-btn", href: `https://xm.gameyw.netease.com/wyds_dl_lushibox${version !== null && version !== void 0 ? version : ""}` }),
        React.createElement("div", { className: Classnames("mobile-scroll-tips", { hidden: index === 2 }) }),
        React.createElement("div", { className: Classnames("mobile-tips-modal", { hidden: !tipsStatus }) },
            React.createElement("div", { className: "mobile-tips-content" },
                React.createElement(CopyToClipboard, { text: "https://lushi.163.com/", onCopy: () => {
                        alert("复制成功");
                    } },
                    React.createElement("div", { className: "copy-btn" }))),
            React.createElement("div", { className: "mobile-tips-close", onClick: () => setTipsStatus(false) }))));
}
